#sr-no{
    width: 60px;
}
#matter{
    width: 40rem;
}
#date{
    width: 10rem;
}
.content-wrapper{
    margin-top:2rem;
}
#form-labels{
    width:10rem;
}
#button{
    margin-left:10rem;
}
#form{
    margin-bottom: 3rem;
}
.table-light {
    font-size: 15px;
}
p{
    font-size:17px;
}
.heading{
    margin-top:2rem;
}
.table{
    margin-top:2rem;
}
.right-container{
    margin:0 2rem;
}
.form{
    margin-top:2rem;
}
#labels{
    font-size: 16px;
    line-height: 2px;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    width: 18rem;
}
.css-1nrlq1o-MuiFormControl-root {
    width: 30rem;
}
.tablebutton{
    border: none;
    outline: none;
    font-size:18px;
}
.move-btn{
    outline:none;
    border: none;
}
.quill{
    margin-top:2rem;
}
#button2{
    margin-left:2rem;
}
.table-archives{
    margin-top:2rem;
}
.final-regulation{
    margin-top:2rem;
}
.heading-commission{
    margin-top: 2rem;
}
.regulation-select-option{
    width: 50rem;
}
#reg-si-no{
    margin-left:3rem;
}
.date-feilds{
    margin-top:2rem;
}
#order-select-option{
    width:45rem;
}
.report-select-option{
    width:30rem;
}
.gallery-select-option{
    width:21rem;
}
.innerNewsPage{
    margin-top: 2rem;
}